"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.DATA_STORE_PATHS = void 0;

/**
 * WARNING: Don't change any path unless you have checked:
 *    - no hardcoded path is in any config.js of any target affected OR you changed it
 *    - no old apps are affected OR you implemented an upgrade routine
 */
var DATA_STORE_PATHS = {
  QD_LEARNING_PRECONDITION_STATE: 'private.qdLearning.preconditionState',
  QD_LEARNING_PRECONDITION_SUCCESSION_INDEX: 'private.qdLearning.precondition.successionIndex',
  QD_LEARNING_PRECONDITION_ANSWER_STATUS_CORRECT: 'private.qdLearning.precondition.answerStatus.correct',
  // private, do not sync
  PRIVATE: 'private',
  QD_POST_QUEUE: 'private.qdPostQueue',
  QD_USER_IMAGE_PATH: 'private.qdUserImg',
  // device specific, do not sync  --> moved to private.* via DataMigrator
  // this is only kept here for not changing all conditions in the content
  DEVICE: 'device',
  HOMECOURSE_ID: 'private.homecourseId',
  METADATA: 'private.metadata',
  FILELIST_PUBLISHED: 'private.filelist.published',
  LOGGED_IN: 'private.loggedIn',
  CONTENT_TAG: 'private.contentTag',
  APP_INSTANCE: 'private.application',
  APP_INSTANCE_PATH: 'private.application.instanceId',
  APP_INSTANCE_CREATION_DATE: 'private.application.instanceIdCreationDate',
  APP_INSTANCE_AUTH_PATH: 'private.application._auth',
  LOG_MESSAGES: 'private.logs',
  LOG_MESSAGES_COUNT: 'private.logsCount',
  DOWNLOADED_FILES: 'private.downloads',
  KESI_VISIBILITIES: 'private.visibilities',
  KESI_LANGUAGE: 'private.language',
  KESI_UPDATE_VALUE: 'private.kesiUpdateValue',
  KESI_HIDE_TILE_ANIMATION: 'private.hideTileAnimation',
  IS_KESI_MASTER: 'private.isKesiMaster',
  IFRAME_DATA: 'private.iframedata',
  SNOM_REDIRECT_URL: 'private.snom_redirect_url',
  CONNECT: 'private.connections',
  // KESI_CONNECT_AUTH: 'private.kesi_connection.auth',
  CONNECT_PARTNER_ACTIVE: 'private.kesiqd.partnerActive',
  CONNECT_PARTNER_AVAILABLE: 'private.kesiqd.partnerAvailable',
  CONNECT_PARTNER_IDS: 'private.kesiqd.partner_ids',
  CONNECT_PARTNER_PRESENCES: 'private.kesiqd.partner_presences',
  // CONNECT_CONNECTION_CODE: 'private.kesiqd.connection_code',
  GEOLOCATION: 'private.geolocation',
  EDITOR_ROUND: 'private.questionEditor.round',
  EDITOR_ROUND_QUESTIONS: 'private.questionEditor.round.questions',
  GWG_CERTIFICATE_SENT: 'private.gwgSent',
  WELCOME_ALERT_VERSION: 'private.welcomealert.version',
  TC_REGION_INFO_CACHE: 'private.tandc.regionInfoCache',
  TC_PERMISSIONS_ASKED_BEFORE: 'private.tandc.permissions.asked',
  TC_NOTIFICATION_ID: 'private.tandc.notificationId',
  IN_CHAT: 'private.inChat',
  IN_LIVE_TICKER: 'private.inLiveTicker',
  CHAT_FINISHED: 'private.chatFinished',
  CHAT_STATUS: 'private.chatStatus',
  UPDATE_AVAILABLE: 'private.updateAvailable',
  SUSPEND_TIME: 'private.suspendTime',
  LIVETICKER_MESSAGES: 'private.livetickerMessages',
  LIVETICKER_CHANNELS: 'private.livetickerChannels',
  LOW_POWER_MODE_ALERT_TIMESTAMP: 'private.lowPowerModeAlertTimestamp',
  // ERGO
  ERGO_ACTIVATED: 'private.ergo.isActivated',
  ERGO_STRUCTURE_DATA: 'private.ergo.structureData',
  ERGO_SUMMED_STRUCTURE_UNITS: 'private.ergo.summedStructureUnits',
  ERGO_USER_PICTURES: 'private.ergo.userPictures',
  // DEGES
  DEGES_ACTIVATED: 'private.deges.isActivated',
  // IEB
  IEB_ACTIVATED: 'private.ieb.isActivated',
  // EPR
  EPR_ACTIVATED: 'private.epr.isActivated',
  EPR_USER_DATA: 'public.epr.userData',
  EPR_ERGOPRAKTIKER_CERT_DATE: 'public.features.epr.ergoPraktikerCertDate',
  // EQUEO
  EQUEO_ACTIVATED: 'private.equeo.isActivated',
  EQUEO_HOST_NAME: 'private.hostName',
  BASE_START_DATE: 'public.equeo.baseStartDate',
  BASE_QD_NICKNAME: 'private.equeo.qdBaseNickname',
  // Africa app
  AFRICA_ACTIVATED: 'private.africa.isActivated',
  // public, that means the same for each device of the same user
  PUBLIC: 'public',
  // TRACKING_DATA: 'public.tracking', -> moved to client part
  KESI_ADDITIONAL_DATA: 'public.additionalData',
  FEATURES: 'public.features',
  FILTER_FAVORITES: 'public.showFavsOnly',
  QD_CATEGORY_DESCRIPTION_SEEN: 'private.qd.categoriesSeen',
  KESI_ACTIVE_DEVICE: 'public.kesi.activeDevice',
  KESI_QUESTION_LOG: 'public.kesi.questionlog',
  KESI_LIKES: 'public.kesi.likes',
  KESI_ALL_LIKES: 'public.kesi.allLikes',
  KESI_SHOW_UNPUBLISHED_CONTENT: 'public.kesi.showUnpublishedContent',
  KESI_OPEN_TILE: 'public.kesi.openTile',
  IS_KESI_EXPERT: 'public.kesi.isExpert',
  QD_ROUND_COUNT: 'private.qd.roundCount',
  QD_GROUP_USERS: 'private.qd.groupUsers',
  QD_GROUP_ID: 'private.qd.groupId',
  QD_GROUP: 'private.qd.group',
  QD_GROUP_MODE: 'private.qd.groupMode',
  QD_SUBJECT_ID: 'private.qd.subjectId',
  QD_USER: 'private.qd.user',
  QD_USER_PICTURE: 'private.qd.user.picture',
  QD_USER_NICKNAME: 'private.qd.user.nickname',
  QD_USER_PLAYERSTATE: 'private.qd.user.playerState',
  QD_USER_POINTS: 'private.qd.userPoints',
  QD_USER_AUTH: 'private.qd.userAuth',
  QD_USER_AUTH_ID: 'private.qd.userAuth.id',
  QD_USER_AUTH_AUTH: 'private.qd.userAuth._auth',
  QD_OPPONENTS: 'private.qd.opponents',
  QD_SELECTED_OPPONENT: 'private.qd.selectedOpponent',
  QD_SELECTED_OPPONENT_PICTURE: 'private.qd.selectedOpponent.picture',
  QD_SELECTED_OPPONENT_NICKNAME: 'private.qd.selectedOpponent.nickname',
  QD_CATEGORIES: 'private.qd.categories',
  QD_CATEGORIES_OFFLINE: 'private.qd.categoriesOffline',
  QD_LEARNING_STATE_OFFLINE: 'private.qd.learningStateOffline',
  QD_USER_SUBJECTS: 'private.qd.userSubjects',
  QD_SUBJECTS: 'private.qd.subjects',
  QD_USER_SUBJECTS_TO_ADD: 'public.qd.userSubjectsToAdd',
  QD_ALL_QUESTIONS: 'private.qd.allQuestions',
  QD_LAST_OFFLINE_QUESTION_CHECK_TIMESTAMP: 'private.qd.lastOfflineQuestionCheckTimestamp',
  QD_ALL_HISTORY: 'private.qd.allHistory',
  QD_ANSWERS_OFFLINE: 'private.qd.answers',
  QD_OFFLINE_IS_INITIALIZED: 'private.qd.offline.isInitialized',
  QD_SELECTED_CATEGORY: 'private.qd.selectedCategory',
  QD_CURRENT_QUESTION_STATUS: 'private.qd.currentQuestionStatus',
  QD_CURRENT_QUESTION_STATUS_NUMBER: 'private.qd.currentQuestionStatus.number',
  QD_SINGLE_PLAYER_QUESTION_INDEX: 'private.qd.singlePlayerQuestionIndex',
  QD_GAME: 'private.qd.game',
  QD_GAME_ROUNDS: 'private.qd.game.rounds',
  QD_ALL_GAME: 'private.qd.allGame',
  QD_COMPLETED_GAME_IDS: 'private.qd.completedGameIds',
  QD_EXPIRED_GAME_IDS: 'private.qd.expiredGameIds',
  QD_NETWORK_STATUS: 'private.qd.networkStatus',
  QD_ACTIVATION_CODE_ON_REGISTRATION: 'private.qd.activationCodeOnRegistration',
  QD_TRAINER_CODE_ON_REGISTRATION: 'private.qd.trainerCodeOnRegistration',
  QD_ACTIVATION_CODE_ON_RESET: 'private.qd.activationCodeOnReset',
  QD_TRAINER_CODE_ON_RESET: 'private.qd.trainerCodeOnReset',
  QD_ACTIVATED_FEATURES: 'private.qd.activatedFeatures',
  QD_HISTORY_BASED_FEEDBACK: 'private.qd.historyBasedFeedback',
  QD_LEAGUE_NAME: 'private.qd.leagueName',
  QD_SEASONS: 'private.qd.seasons',
  QD_LEARNING_STATE: 'private.qd.learningState',
  QD_MY_LEARNING_STATE: 'private.qd.myLearningState',
  QD_NICKNAME: 'private.qd.nickname',
  QD_PRESENCES: 'private.qd.presences',
  QD_SHOW_PRESENCES: 'private.qd.showPresences',
  QD_TERMS_ACCEPTED: 'client.qd.termsAccepted',
  QD_TERMS_INFO: 'client.qd.termsInfo',
  // QD_FEATURES: 'private.qd.features',   // not in use anymore(?)
  QD_SHOW_FAVS_ONLY: 'private.qd.showFavsOnly',
  QD_GROUP_SUPPORTS_TOURNAMENTS: 'private.qd.tournamentsSupported',
  QD_GROUP_WINNER_TAKES_IT_ALL: 'private.qd.winnerTakesItAll',
  QD_GROUP_HIDE_FEEDBACK: 'private.qd.hideFeedback',
  QD_GROUP_MIN_CORRECTS_FOR_LEARNED: 'private.qd.minCorrectsForLearned',
  QD_GROUP_QUESTION_SELECTION_TYPE: 'private.qd.questionSelectionType',
  QD_TOURNAMENT_IS_ACTIVE: 'private.qd.tournamentIsActive',
  QD_TOURNAMENTS: 'private.qd.tournaments',
  QD_MY_TOURNAMENT: 'private.qd.myTournament',
  QD_MY_TOURNAMENT_ID: 'private.qd.myTournament.id',
  QD_MY_TOURNAMENT_TEAMS: 'private.qd.myTournament.teams',
  QD_MY_TOURNAMENT_PHASE: 'private.qd.myTournament.phase',
  QD_MY_TOURNAMENT_MAX_CONCURRENT_GAMES: 'private.qd.myTournament.concurrentGamesPerUser',
  QD_THEME: 'private.qd.theme',
  QD_TOURNAMENT_TEAM_POINTS: 'private.qd.tournamentTeamPoints',
  QD_TOURNAMENT_OPPONENTS_PLAYED: 'private.qd.tournamentOpponentsPlayed',
  QD_MY_TEAM_NAME: 'private.qd.myTeamName',
  GWG_BCC_ALLOWED: 'public.gwg.bccAllowed',
  GWG_BCC_ADRESSES: 'public.gwg.bccAdresses',
  GWG_NO_BCC_OPTIONS: 'private.gwg.doNotShowBccOptions',
  CHAT_MESSAGES: 'public.chats',
  CHAT_CHANNELS: 'public.chatChannel',
  QD_ADDITIONAL_FEATURES: 'private.qd.additionalFeatures',
  QD_SHOW_TRAINER_MENU: 'private.qd.showTrainerMenu',
  QD_MESSAGES: 'private.qd.messages',
  QD_CURRENT_ROUND_STATES: 'private.qd.currentRoundStates',
  MAZARS_CERT_FIRST_NAME: 'public.mazars.certFirstName',
  MAZARS_CERT_LAST_NAME: 'public.mazars.certLastName',
  MAZARS_CERT_SENT: 'public.mazars.certSent',
  // SCHICHTBOX
  SCHICHTBOX_EARLY_PUSH_TIME: 'public.schichtbox.earlyPushTime',
  SCHICHTBOX_LATE_PUSH_TIME: 'public.schichtbox.latePushTime',
  SCHICHTBOX_START_DATE: 'public.schichtbox.startDate',
  SCHICHTBOX_CUSTOMER: 'public.schichtbox.customer',
  SCHICHTBOX_DEMO_MODE: 'public.schichtbox.demoMode',
  SCHICHTBOX_ACTIVATION_CODE: 'public.schichtbox.activationCode',
  SCHICHTBOX_DEMO_CURRENT_STEP: 'public.schichtbox.demoCurrentStep',
  SCHICHTBOX_REMINDER: 'public.schichtbox.reminder',
  SCHICHTBOX_STORAGE: 'public.schichtboxStorage',
  SCHICHTBOX: 'public.schichtbox',
  SCHICHTARBEIT: 'public.schichtarbeit',
  // USER_PROFILE
  USER_PROFILE: 'public.userProfile',
  USER_MAIL_ADRESS: 'public.userProfile.email',
  BOOKED_EVENT_DATES: 'public.bookings.bookedEventDates',
  BOOKING_TAGS: 'public.bookings.bookingTags',
  MOBILE_BOOKING_TAGS: 'public.bookings.mobileBookingTags',
  COMPLETION_TAGS: 'public.bookings.completionTags',
  MOBILE_COMPLETION_TAGS: 'public.bookings.mobileCompletionTags',
  MAX_CANCEL_TIME: 'public.bookings.maxCancelTime',
  IS_COMMENTER: 'public.userProfile.isCommenter',
  // ERGO_ZEP
  ERGO_USER_DATA: 'public.ergo.userData',
  ERGO_CONTRACTS: 'public.ergo.contracts',
  ERGO_STRUCTURE_UNITS: 'public.ergo.structureUnits',
  ERGO_STRUCTURE_TARGETS: 'public.ergo.structureTargets',
  ERGO_USER_TARGETS: 'public.ergo.userTargets',
  ERGO_REQUESTCODE: 'public.ergo.activationCode',
  // ERGO_PROTHEK
  ERGOPRO_FAVORITES: 'public.ergoPro.favs',
  ERGOPRO_SHOW_ZEP: 'public.ergoPro.showZep',
  SNOO_SETTINGS_SEND_PUSH: 'public.snoo.sendPush',
  SNOO_SETTINGS_PUSH_TIME: 'public.snoo.pushTime',
  SNOO_AVAILABLE_LECTURES: 'public.snoo.available',
  SNOO_NEXT_SPOC_TITLE: 'public.snoo.nextSpocTitle',
  SNOO_NEXT_SPOC_SUBTITLE: 'public.snoo.nextSpocSubtitle',
  SNOO_NO_MORE_PUSH_FOR_CONTENT: 'public.snoo.noMorePushForContent',
  SNOO_COUNT_READ_LECTURES: 'private.snoo.countRead',
  SNOO_UPPEST_LECTURE_ID: 'private.snoo.uppestLectureId',
  SNOO_SCROLL_TOP_COUNTER: 'private.snoo.scrollTopCounter',
  // Not possible because target is undefined, but is used in KESI2Helper
  // CERTIFICATE_PATH: `public.${target.requiredFeature}.certificateDate`
  // travel and care
  TC_CURRENT_REGION_CODE: 'public.tandc.currentRegionCode',
  TC_PHONE_NUMBER: 'public.tandc.phoneNumber',
  TC_EMERGENCY_NUMBERS: 'public.tandc.emergencyNumbers',
  TC_FLEXMENU: 'private.tandc.flexMenu',
  TC_HOME_REGION_CODE: 'public.tandc.homeRegionCode',
  TC_USER_REGION_CODES: 'public.tandc.userRegionCodes',
  TC_PRIVACY_ACCEPTED: 'public.tandc.privacyAccepted',
  TC_LANGUAGE: 'public.tandc.language',
  TC_LANGUAGE_CODE: 'public.tandc.language.languageCode',
  TC_POLICY_NUMBER: 'public.tandc.policyNumber',
  TC_POLICY_TYPE: 'public.tandc.policyType',
  TC_POLICY_IS_TEST: 'public.tandc.isTestPolicy',
  TC_TARIFF: 'public.tandc.tarif',
  TC_AGENCY: 'public.tandc.agency',
  TC_USER_ROLE: 'public.tandc.userRole',
  TC_LAST_PHONE_DIALOG: 'private.tandc.lastPhoneDialog',
  TC_SAFETURE_KEYS: 'private.tandc.safeture.keys',
  TC_SAFETURE_API_KEY: 'private.tandc.safeture.keys.API_KEY',
  TC_SAFETURE_SUBSCRIPTION_ID: 'private.tandc.safeture.keys.SUBSCRIPTION_ID',
  TC_SAFETURE_API_KEY_CUSTOMER: 'private.tandc.safeture.keys.API_KEY_CUSTOMER',
  TC_SAFETURE_SUBSCRIPTION_ID_CUSTOMER: 'private.tandc.safeture.keys.SUBSCRIPTION_ID_CUSTOMER',
  TC_PHONE_NUMBER_REMINDER: 'private.tandc.phoneNumberReminder',
  TC_LOGGED_IN_USER: 'private.tandc.loggedInUser',
  TC_HARDCODED_LOGGED_IN: 'private.tandc.hardcodedLoggedIn',
  // remove after ITB
  TC_CORPORATE_CARD_ICON: 'private.tandc.corporateCardIcon',
  TC_SHOW_CORPORATE_CARD: 'private.tandc.showCorporateCard',
  TC_WARNING_ACTIVATED: 'private.tandc.warningState',
  TC_TRACKING_LEVEL: 'private.tandc.trackingLevel',
  TC_DOCUMENTSAFE_IN_USE: 'private.tandc.documentsafe.inUse',
  TC_DOCUMENTSAFE_WITHOUT_PIN: 'private.tandc.documentsafe.withoutPIN',
  TC_DOCUMENTSAFE_LAST_NOTIFIED_REGION: 'private.tandc.lastNotifiedRegion',
  TC_DOCUMENTSAFE_DOCUMENT_COUNT: 'client.tandc.documentsafe.documentCount',
  TC_USE_NEW_ICON: 'public.tandc.newStyling',
  TC_CURRENCIES: 'client.tandc.currencies.ids',
  TC_CURRENCY_LAST_AMOUNT: 'private.tandc.currencies.lastAmount',
  TC_CURRENCY_LAST_CURRENCY: 'private.tandc.currencies.lastCurrency',
  TC_ASK_FOR_APP_TURNED_ON: 'private.tandc.userRating.turnedOn',
  // user is asked if this path is true + at least one of the following
  TC_ASK_FOR_APP_RATING_AFTER_NEXT_PUSH_OPENED: 'private.tandc.userRating.askAfterNextPushOpened',
  TC_ASK_FOR_APP_RATING_ON_NEXT_TILE_CLOSING: 'private.tandc.userRating.askOnNextTileClosing',
  TC_ASK_FOR_APP_RATING_OPTIONS: 'private.tandc.userRating.alertOptions',
  TC_SETTINGS_OPEN: 'private.tandc.settingsOpen',
  TC_WAR_RISK_COUNTRIES: 'private.tandc.warRiskCountries',
  TC_WAR_COUNTRIES: 'private.tandc.warCountries',
  HANDLED_SYSMESSAGES: 'private.sysMessages.handled',
  APPSFLYER_REFERRER: 'public.appsflyer.referrer',
  TC_SAFETURE_REGISTRATION_ID: 'public.tandc.safeture.registrationId',
  // new GWS receiver key (an UUID)
  TC_SAFETURE_RECEIVER_KEY: 'public.tandc.safeture.receiverKey',
  // old GWS receiver key (a number)
  TC_SAFETURE_DATA_MIGRATED: 'public.tandc.safeture.dataMigrated',
  TC_SAFETURE_PHONE_NUMBER_MIGRATED: 'public.tandc.safeture.phoneNumberMigrated',
  TC_MIGRATED_POLICY_NUMBER: 'public.tandc.safeture.policyNumberMigrated',
  TC_MIGRATED_RECEIVER_KEY: 'public.tandc.safeture.receiverKeyMigrated',
  I40_USER_ROLE: 'public.i40.userrole',
  ABLY_ENABLED: 'client.ably.enabled',
  // FCM_TOKEN: 'public.fcmtoken',
  // APN_TOKEN: 'public.apntoken',
  // PATHS from server (for sysMessages etc)
  SERVER: 'server',
  SERVER_SYS_MESSAGES: 'server.sysMessages',
  // CLIENT (only synched to the server)
  CLIENT: 'client',
  FCM_TOKEN: 'client.fcmtoken',
  APN_TOKEN: 'client.apntoken',
  TRACKING_DATA: 'client.tracking',
  ERGO_USER_MAIL: 'client.ergo.userEmail'
};
exports.DATA_STORE_PATHS = DATA_STORE_PATHS;