"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getObjectValueWithFallbacks = void 0;

var getObjectValueWithFallbacks = function getObjectValueWithFallbacks(object, value, fallbackObject, fallbackValue, secondFallbackString) {
  if (object && object[value]) {
    return object[value];
  }

  return fallbackObject && fallbackObject[fallbackValue] ? fallbackObject[fallbackValue] : secondFallbackString;
};

exports.getObjectValueWithFallbacks = getObjectValueWithFallbacks;