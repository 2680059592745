"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  LOADING_STRING_1: {
    de: '... dass eine Anfrage an Google in 0,2 Sekunden über 2500 km bis zum nächsten Rechenzentrum zurücklegt?'
  },
  LOADING_STRING_2: {
    de: '... dass die erste E-Mail schon 1971 verschickt wurde?'
  },
  LOADING_STRING_3: {
    de: '... dass bis 2020 ca. 80% aller Industrieunternehmen ihre Wertschöpfungskette digitalisieren werden?'
  },
  LOADING_STRING_4: {
    de: '... dass pro Minute ungefähr 204 Millionen E-Mails über das Internet verschickt werden?'
  },
  LOADING_STRING_5: {
    de: '... dass 41% der deutschen Konsumenten die Produkte, die sie im Laden kaufen, vorher im Internet recherchiert haben?'
  },
  LOADING_STRING_6: {
    de: '... dass weltweit 72% aller CEOs glauben, dass die nächsten drei Jahre für die Entwicklung ihrer Branche wichtiger sind als die letzten 50?'
  },
  LOADING_STRING_7: {
    de: '... dass das World Wide Web schon 28 Jahre alt ist?'
  },
  LOADING_STRING_8: {
    de: '... dass von den 7 Milliarden Menschen auf der Erde über 2,4 Milliarden Menschen das Internet nutzen?'
  },
  LOADING_STRING_9: {
    de: '... dass in den USA 73% aller Unternehmen Jobs mithilfe von sozialen Medien besetzen?'
  },
  LOADING_STRING_10: {
    de: '... dass das Internet so schwer ist wie eine Erdbeere?<br><p style="font-size: 24px; padding-top: 30px;">Wenn man das Gewicht aller Elektronen zusammenrechnet, über deren Bewegung Daten im Internet geschickt werden, kommt man auf ca. 50 Gramm.</p>'
  },
  LOADING_STRING_11: {
    de: '... dass alle 60 Sekunden YouTube-Videomaterial mit einer Gesamtlänge von 72 Stunden hochgeladen wird?'
  },
  LOADING_STRING_12: {
    de: '... dass Daten kabellos mit Lasern so schnell übertragen werden können wie mit Glasfaserkabeln?<br><p style="font-size: 24px; padding-top: 30px;">In einem Pilotprojekt in Indien erhalten Bewohner abgelegener Bergdörfer so Zugang zum Netz.</p>'
  },
  LOADING_STRING_13: {
    de: '... dass 1099511627776 Byte so viel sind wie 1&nbsp;Terrabyte?'
  },
  LOADING_STRING_14: {
    de: '... dass Südkorea und Japan die schnellste Internetverbindung haben?'
  },
  LOADING_STRING_15: {
    de: '... dass die UN Internetzugang zu einem Menschenrecht erklärt hat?<br><p style="font-size: 24px; padding-top: 30px;">Menschen den Zugang zum Internet zu verwehren, verstößt also gegen internationales Recht.</p>'
  },
  LOADING_STRING_16: {
    de: '... dass der Erfinder des World Wide Web Tim Berners-Lee 2004 von Queen Elizabeth für seine Verdienste als Wissenschaftler zum Ritter geschlagen wurde?'
  },
  LOADING_STRING_17: {
    de: '... dass für ungefähr 50% der Nutzer das Mobiltelefon der primäre Zugang zum Internet ist?'
  },
  LOADING_STRING_18: {
    de: '... dass die Web-Adresse einer Internetseite „Uniform Resource Locator“ (URL) bezeichnet wird?'
  },
  LOADING_STRING_19: {
    de: '... dass sich 70% aller Facebook-Nutzer mindestens einmal am Tag einloggen?'
  },
  LOADING_STRING_20: {
    de: '... dass 92% aller Jugendlichen täglich online sind?'
  },
  LOADING_STRING_21: {
    de: '... dass in den USA im Jahr 2017 ca. 13,51 Milliarden € für Social Media Marketing ausgegeben wurde?'
  },
  LOGGED_OUT_INIT_LOGIN: {
    de: 'Du hast Dich erfolgreich abgemeldet.'
  },
  INIT_LOGIN: {
    de: 'Lade Anmeldung...'
  },
  LOADING_QUESTION: {
    de: 'Wusstest Du schon ...',
    en: 'Did you know ...',
    fr: 'Saviez-vous que ...'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;