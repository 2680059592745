"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getUserAgent = void 0;

var _config = _interopRequireDefault(require("../../targets/cord_deutschewohnen/config.js"));

var getUserAgent = function getUserAgent() {
  return "".concat(_config["default"].userAgentAppName || window.BuildInfo.name, " ").concat(window.BuildInfo.version, "; ").concat(window.device.manufacturer, " ").concat(window.device.model, "; ").concat(window.device.platform, " ").concat(window.device.version);
};

exports.getUserAgent = getUserAgent;