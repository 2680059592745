"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.preconditionAction = void 0;

var _config = _interopRequireDefault(require("../../targets/cord_deutschewohnen/config.js"));

var _AlertHelper = require("../helpers/AlertHelper");

var _MuiAlertHelper = require("../helpers/MuiAlertHelper");

var _strings = require("../../config/strings");

var preconditionAction = function preconditionAction(precondition, onRetry) {
  console.log('executing action for precondition', precondition);

  if (!precondition) {
    return;
  }

  if (precondition.actionType === 'alert') {
    var title = precondition.alert ? precondition.alert.title : '';
    var text = precondition.alert ? precondition.alert.text : '';

    if (precondition.alert.i18n_title) {
      title = (0, _strings.getString)(precondition.alert.i18n_title);
    }

    if (precondition.alert.i18n_text) {
      text = (0, _strings.getString)(precondition.alert.i18n_text);
    }

    (0, _AlertHelper.showAlertWithOptions)({
      message: text,
      title: title,
      buttons: [{
        label: 'OK',
        callback: onRetry
      }]
    }); // showAlert(text, title)
  }

  if (precondition.actionType === 'muialert') {
    var _title = precondition.alert ? precondition.alert.title : '';

    var _text = precondition.alert ? precondition.alert.text : '';

    if (precondition.alert.i18n_title) {
      _title = (0, _strings.getString)(precondition.alert.i18n_title);
    }

    if (precondition.alert.i18n_text) {
      _text = (0, _strings.getString)(precondition.alert.i18n_text);
    }

    (0, _MuiAlertHelper.showAlert)(_text, _title, true, [{
      label: 'OK',
      callback: function callback() {
        onRetry();
      }
    }]);
    return;
  }

  if (precondition.actionType === 'clientDialog') {
    console.log('precondition with type clientDialog not yet implemented.');
    (0, _AlertHelper.showAlertWithOptionsInDebugMode)({
      message: 'precondition with type clientDialog not yet implemented.',
      title: 'NOT IMPLEMENTED'
    }); // showAlertInDebugMode('precondition with type clientDialog not yet implemented.', 'NOT IMPLEMENTED')

    return;
  }

  if (precondition.actionType === 'serverDialog') {
    (0, _AlertHelper.showExternalWebsite)(precondition.serverDialog, function (error) {
      console.error(error);
    }, precondition.supportsMessaging, onRetry);
    return;
  }

  if (_config["default"].additionalPreconditionActions) {
    var actionFound = false;

    _config["default"].additionalPreconditionActions.forEach(function (action) {
      if (action.actionType === precondition.actionType) {
        console.log('additionalPrecondition', precondition);
        action.callback(onRetry, precondition.params);
        actionFound = true;
      }
    });

    if (actionFound) {
      return;
    }
  }

  console.log("unknown precondition action type '".concat(precondition.actionType, "'")); // showAlertInDebugMode(`unknown precondition action type ${precondition.actionType}`, 'ERROR')
};

exports.preconditionAction = preconditionAction;