"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getQuizModeChooserStyles = void 0;

var _ColorHelper = require("../../../../helpers/ColorHelper");

var _ObjectHelper = require("../../../../helpers/ObjectHelper");

var _cssConstants = _interopRequireDefault(require("../../../../../targets/cord_deutschewohnen/cssConstants.js"));

var getQuizModeChooserStyles = function getQuizModeChooserStyles(componentState) {
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var singlePlayerStyle = {
    backgroundColor: _cssConstants["default"].colors.singlePlayerButtonBackground ? _cssConstants["default"].colors.singlePlayerButtonBackground : (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'singlePlayerButtonBackground', defaultTheme, 'singlePlayerButtonBackground', _ColorHelper.COLOR.BLACK),
    border: "3px solid ".concat(_cssConstants["default"].colors.singlePlayerButtonBorder ? _cssConstants["default"].colors.singlePlayerButtonBorder : (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'singlePlayerButtonBorder', defaultTheme, 'singlePlayerButtonBorder', _ColorHelper.COLOR.WHITE))
  };
  var battleStyle = {
    backgroundColor: _cssConstants["default"].colors.battleButtonBackground ? _cssConstants["default"].colors.battleButtonBackground : (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'battleButtonBackground', defaultTheme, 'battleButtonBackground', _ColorHelper.COLOR.BLACK),
    border: "3px solid ".concat(_cssConstants["default"].colors.battleButtonBorder ? _cssConstants["default"].colors.battleButtonBorder : (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'battleButtonBorder', defaultTheme, 'battleButtonBorder', _ColorHelper.COLOR.WHITE))
  };
  var statsStyle = {
    backgroundColor: _cssConstants["default"].colors.statsButtonBackground ? _cssConstants["default"].colors.statsButtonBackground : (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'buttonBackground', defaultTheme, 'buttonBackground', _ColorHelper.COLOR.BLACK),
    border: "3px solid ".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'battleButtonBorder', defaultTheme, 'battleButtonBorder', _ColorHelper.COLOR.WHITE))
  };
  var backButtonStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'buttonCaption', defaultTheme, 'buttonCaption', _ColorHelper.COLOR.WHITE),
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK)
  };
  return {
    singlePlayerStyle: singlePlayerStyle,
    battleStyle: battleStyle,
    statsStyle: statsStyle,
    backButtonStyle: backButtonStyle
  };
};

exports.getQuizModeChooserStyles = getQuizModeChooserStyles;