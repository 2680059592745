"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.register = register;
exports.unregister = unregister;

var _MessagingService = _interopRequireDefault(require("../MessagingService"));

var _RegisterInstance = _interopRequireDefault(require("../RegisterInstance"));

/**
 * Created by falknisius on 15.06.17.
 */
var DATA_CHANNEL = 'private:equeoqd:'; // ensure instance id is generated

_RegisterInstance["default"].init(); // init channel
// messageService.getChannel(DATA_CHANNEL)


function register(onDataReceivedCallback, userId) {
  _MessagingService["default"].registerChannel("".concat(DATA_CHANNEL).concat(userId), onDataReceivedCallback);
}

function unregister(onDataReceivedCallback, userId) {
  _MessagingService["default"].unregisterChannel("".concat(DATA_CHANNEL).concat(userId), onDataReceivedCallback);
}