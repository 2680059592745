"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var css = {
  'colors': {
    'titleBackgroundOverlay': '#c8c8e1',
    'tileFontColor': '#1006a0',
    'tileSubtitleColor': '#3c7de1',
    'tileBackgroundColor': '#FFFFFF',
    'navbarBackground': '#FFFFFF',
    'navbarBorderBottom': '3px solid #1006A0',
    'navbarText': '#1006A0',
    'menuBackground': '#FFFFFF',
    'buttonBorder': '#1006A0',
    'buttonBorderSecondary': '#1006A0',
    'buttonCaption': '#FFFFFF',
    'buttonCaptionSecondary': '#FFFFFF',
    'buttonBackground': '#1006A0',
    'buttonBackgroundLight': '#c6defa',
    'buttonBorderLight': '#8faed6',
    'buttonCaptionLight': '#8faed6',
    'buttonBackgroundDark': '#f4f4f4',
    'buttonBorderDark': '#f4f4f4',
    'buttonCaptionDark': '#1006A0',
    'lightText': '#1006A0',
    'textfieldCaption': '#1006A0',
    'tab': '#39597B',
    'tabBackground': '#FFFFFF',
    'tableDivider': '#D8D8D8',
    'dialogText': '#6f6760',
    'dialogDivider': '#a69b8d',
    'dialogBackground': '#fbf9f7',
    'primaryColor1': '#e83457',
    'primaryColor2': '#e83457',
    'navbarItemColor': '#1006A0',
    'lectureBackButton': '#ffffff',
    'lectureHeaderText': '#1006A0',
    'correct': '#00e841',
    'wrong': '#e83457',
    'quizMainColor': '#1006A0',
    'quizText': '#FFFFFF'
  },
  'fontFamily': 'Typold',
  'navBarMaxWidth': '45%',
  'navBarContainerLeftTop': '20px',
  'navBarHeight': 64,
  'navBarHeightPx': '64px',
  'navBarLogoMarginTop': 10,
  'navBarRightSideButtonsTop': '24px',
  'navBarLogoLeft': '10%',
  'navBarLogoHeight': '50px',
  'navBarLogoTop': '20px',
  'thresholdScreenWidth': '800px',
  'mainMenuWidth': '300px',
  'navBarMargin': '0 40px',
  'navBarMaxHeight': '60px',
  'navBarTextAlign': 'center',
  'navBarVerticalAlign': 'middle',
  'navBarFontSize': '16px',
  'navBarFontWeight': 'bold',
  'navbarTitleColor': '#1006A0',
  'navBarPaddingTop': '14px',
  'navBarLineHeight': '19px',
  'burgerIconLeft': '10px',
  'navBarOverflow': 'hidden',
  'lectureBackButton': 'green',
  'headerMinHeight': '200px',
  'categoryChooserHeight': '140px',
  'categoryHeaderHeight': '100px',
  'appMaxWidth': 1024,
  'tiles': {
    'backgroundHeight': 100,
    'minTitleHeight': 101,
    'titleSize': 12,
    'subtitleSize': 14,
    'iconSize': 80,
    'iconRight': '50%',
    'iconTransform': 'translateX(50%)',
    'backgroundColor': 'rgba(255,255,255,0.7)',
    'titleBackgroundColor': '#c8c8e1',
    'blur': 'blur(10px)',
    'tileFontWeight': 600,
    'tileSubtitleFontWeight': 600,
    'subtitleBottom': '5px' // 'boxShadow': '8px 16px 34px -5px rgba(133,133,133,0.56)',
    // 'borderRadius': '15px'

  }
};
var _default = css;
exports["default"] = _default;