"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs2/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getQuestionOverlayIconStyles = exports.getQuestionStyles = exports.getHeaderStyles = void 0;

var _assign = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/assign"));

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/parse-int"));

var _ColorHelper = require("../../../../helpers/ColorHelper");

var _ObjectHelper = require("../../../../helpers/ObjectHelper");

var _cssConstants = _interopRequireDefault(require("../../../../../targets/cord_deutschewohnen/cssConstants.js"));

var _config = _interopRequireDefault(require("../../../../../targets/cord_deutschewohnen/config.js"));

var _DeviceInfoHelper = _interopRequireDefault(require("../../../../helpers/DeviceInfoHelper"));

var _DataStore = _interopRequireWildcard(require("../../../../KESI2/Stores/DataStore2"));

// all values taken from fullscreen QD
// ratios = width / height
var RATIO_BOX = 0.701404002731941;
var RATIO_QUESTION = 1.459250759141807;
var RATIO_ANSWERS = 1.558092815805964;
var QUESTION_HEIGHT_OF_TOTAL_HEIGHT = 0.564331304173596; // percent

var ANSWER_HEIGHT_OF_TOTAL_HEIGHT = 0.246078230689736; // percent

var ANSWER_HEIGHT_OF_TOTAL_HEIGHT_MC_SC = 0.184558673017302; // percent

var ANSWER_HEIGHT_OF_TOTAL_HEIGHT_MC_SC_MIN = 0.094558673017302; // percent

var ANSWER_WIDTH_OF_TOTAL_WIDTH = 0.479994322214336; // percent

var ANSWER_WIDTH_OF_TOTAL_WIDTH_MC_SC = 0.959988644428672; // percent

var SMALLEST_ACCEPTABLE_HEIGHT = 200; // pixels

var SMALLEST_ACCEPTABLE_WIDTH = 100; // pixels

var getHeaderStyles = function getHeaderStyles(componentStateFromClass) {
  var theme = null;
  var componentState = componentStateFromClass;

  if (!componentState) {
    componentState = _DataStore["default"].getElementByPathImmediately(_DataStore.DATA_STORE_PATHS.QD_THEME);
  }

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var wrongStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerWrongColor', defaultTheme, 'headerWrongColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK))
  };
  var correctStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerCorrectColor', defaultTheme, 'headerCorrectColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK))
  };
  var opponentAnswerStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'opponentAnswer', defaultTheme, 'opponentAnswer', _ColorHelper.COLOR.BLACK)
  };
  var textStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerTextColor', defaultTheme, 'headerTextColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK))
  };
  var answerStatusStyle = {
    main: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerMainColor', defaultTheme, 'headerMainColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK)),
    second: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerSecondColor', defaultTheme, 'headerSecondColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizSecondColor', defaultTheme, 'quizSecondColor', _ColorHelper.COLOR.BLACK)),
    third: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerThirdColor', defaultTheme, 'headerThirdColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizThirdColor', defaultTheme, 'quizThirdColor', _ColorHelper.COLOR.BLACK))
  };
  var statsHeaderStyle = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'statsBackgroundColor', defaultTheme, 'statsBackgroundColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizHomeBackground', defaultTheme, 'quizHomeBackground', _ColorHelper.COLOR.WHITE)),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'statsTextColor', defaultTheme, 'statsTextColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizHomeText', defaultTheme, 'quizHomeText', _ColorHelper.COLOR.BLACK))
  };
  var statsCorrectStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerCorrectColor', defaultTheme, 'headerCorrectColor', _ColorHelper.COLOR.WHITE)
  };
  var statsWrongStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'headerCorrectColor', defaultTheme, 'headerCorrectColor', _ColorHelper.COLOR.WHITE)
  };
  return {
    wrongStyle: wrongStyle,
    correctStyle: correctStyle,
    opponentAnswerStyle: opponentAnswerStyle,
    textStyle: textStyle,
    answerStatusStyle: answerStatusStyle,
    statsHeaderStyle: statsHeaderStyle,
    statsCorrectStyle: statsCorrectStyle,
    statsWrongStyle: statsWrongStyle
  };
};

exports.getHeaderStyles = getHeaderStyles;

var getInsetTop = function getInsetTop() {
  if (CSS.supports('padding-top: constant(safe-area-inset-top)')) {
    var div = document.createElement('div');
    div.style.paddingTop = 'constant(safe-area-inset-top)';
    document.body.appendChild(div);
    var calculatedPadding = (0, _parseInt2["default"])(window.getComputedStyle(div).paddingTop, 10);
    document.body.removeChild(div);
    var valueToReturn = _cssConstants["default"].safeAreaTopDivisor ? calculatedPadding / _cssConstants["default"].safeAreaTopDivisor : calculatedPadding;
    return valueToReturn;
  }

  if (CSS.supports('padding-top: env(safe-area-inset-top)')) {
    var _div = document.createElement('div');

    _div.style.paddingTop = 'env(safe-area-inset-top)';
    document.body.appendChild(_div);

    var _calculatedPadding = (0, _parseInt2["default"])(window.getComputedStyle(_div).paddingTop, 10);

    document.body.removeChild(_div);

    var _valueToReturn = _cssConstants["default"].safeAreaTopDivisor ? _calculatedPadding / _cssConstants["default"].safeAreaTopDivisor : _calculatedPadding;

    return _valueToReturn;
  }
};
/**
 *
 * @param componentState
 * @param parentDomId
 * @param useWindowHeightForMaxHeight Only used if parentDomId is not null
 * @param isSinglePlayer is used to use correct styles for single player
 * @returns {{opponentAnswerStyle: {backgroundColor: *}, boxStyle: {userSelect: string}, correctStyle: {border: string}, answerStyle: {backgroundColor: *, color: *, opacity: number}, questionStyle: {backgroundColor: *, color: *, opacity: number}, wrongStyle: {border: string}, rerenderShortly: boolean}}
 */


var getQuestionStyles = function getQuestionStyles(componentState) {
  var parentDomId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var useWindowHeightForMaxHeight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var isSinglePlayer = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var isInKesi = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var questionNumber = arguments.length > 5 ? arguments[5] : undefined;
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var questionStyle = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'questionBoxColor', defaultTheme, 'questionBoxColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.WHITE)),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizText', defaultTheme, 'quizText', _ColorHelper.COLOR.BLACK),
    opacity: componentState.fontSize !== null ? 1 : 0.1 // otherwise no load event for images
    // border: `1px ${componentState.quiz[componentState.currentTheme].quizBorderColor} solid`

  };
  var answerStyle = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'answerBoxColor', defaultTheme, 'answerBoxColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.WHITE)),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'answerBoxText', defaultTheme, 'answerBoxText', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizText', defaultTheme, 'quizText', _ColorHelper.COLOR.BLACK)),
    opacity: componentState.fontSize !== null ? 1 : 0.1 // otherwise no load event for images
    // border: `1px ${componentState.quiz[componentState.currentTheme].quizBorderColor} solid`

  };
  var answerStyleMCSC = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'answerBoxColor', defaultTheme, 'answerBoxColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.WHITE)),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'answerBoxText', defaultTheme, 'answerBoxText', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizText', defaultTheme, 'quizText', _ColorHelper.COLOR.BLACK)),
    opacity: componentState.fontSize !== null ? 1 : 0.1 // otherwise no load event for images
    // border: `1px ${componentState.quiz[componentState.currentTheme].quizBorderColor} solid`

  }; // only if parent Dom ID exists, see below

  var boxStyle = {
    userSelect: 'none'
  };
  var singlePlayerBoxStyle = {
    userSelect: 'none'
  };
  console.log('parentDomId', parentDomId);

  if (parentDomId) {
    var parent = document.getElementById(parentDomId);

    if (parent) {
      // calculate height and width of question box and answer box
      // question takes half the vertical space
      var maxWidth = parent.offsetWidth;
      var maxHeight = useWindowHeightForMaxHeight ? window.innerHeight - 150 : parent.offsetHeight - 110; // calculate height according to width

      var boxHeight = maxWidth / RATIO_BOX;

      if (boxHeight < SMALLEST_ACCEPTABLE_HEIGHT) {
        boxHeight = SMALLEST_ACCEPTABLE_HEIGHT;
      } else if (boxHeight > maxHeight) {
        boxHeight = maxHeight;
      }

      if (isSinglePlayer) {
        // we need to remove the height of the header
        if (!isInKesi) {
          // but only if we have a header, which we dont, in kesi that is
          boxHeight -= 75;
        }
      }

      console.log('boxHeight', boxHeight, 'maxWidth', maxWidth); // calculate width according to boxHeight

      var boxWidth = boxHeight * RATIO_BOX;

      if (boxWidth < SMALLEST_ACCEPTABLE_WIDTH) {
        boxWidth = SMALLEST_ACCEPTABLE_WIDTH;
        boxHeight = boxWidth / RATIO_BOX;
      } else if (boxWidth > maxWidth) {
        boxWidth = maxWidth;
        boxHeight = boxWidth / RATIO_BOX;
      }

      boxStyle.width = boxWidth;
      boxStyle.height = isSinglePlayer || isInKesi ? '100%' : boxHeight + 150;
      boxStyle.margin = 'auto';
      boxStyle.textAlign = 'center';
      boxStyle.position = 'relative';
      singlePlayerBoxStyle.width = boxWidth;
      singlePlayerBoxStyle.height = boxHeight + 100 - 50;
      singlePlayerBoxStyle.margin = 'auto';
      singlePlayerBoxStyle.textAlign = 'center';
      singlePlayerBoxStyle.position = 'relative'; // calculate styles of question and answer boxes

      questionStyle.width = boxWidth;

      if (_config["default"].allowDifferentQuestionBoxSizes) {
        questionStyle.maxHeight = boxHeight * QUESTION_HEIGHT_OF_TOTAL_HEIGHT;
        questionStyle.height = '100%'; // do not go smaller or feedback icon would look weird -> icon + text (with statistics text) is 190px high + 20px padding

        if (!isInKesi) {
          questionStyle.minHeight = '210px';
        }
      } else {
        questionStyle.height = boxHeight * QUESTION_HEIGHT_OF_TOTAL_HEIGHT;
      }

      if (!isInKesi) {
        answerStyle.width = boxWidth * ANSWER_WIDTH_OF_TOTAL_WIDTH;
        answerStyle.height = boxHeight * ANSWER_HEIGHT_OF_TOTAL_HEIGHT;

        if (_config["default"].useDrupalContent) {
          answerStyleMCSC.minHeight = boxHeight * ANSWER_HEIGHT_OF_TOTAL_HEIGHT_MC_SC_MIN;
        } else {
          answerStyleMCSC.minHeight = boxHeight * ANSWER_HEIGHT_OF_TOTAL_HEIGHT_MC_SC;
        }

        answerStyleMCSC.width = boxWidth;
      } else {
        boxStyle.maxWidth = '100vw';
        boxStyle.overflow = window.innerHeight < 500 ? 'scroll' : 'hidden';
        questionStyle.maxHeight = "".concat(100 * QUESTION_HEIGHT_OF_TOTAL_HEIGHT, "vh");
        questionStyle.maxWidth = '100%';
        var el = document.getElementById("questionBoxFrame_".concat(questionNumber));

        if (el) {
          questionStyle.height = el.offsetWidth / RATIO_QUESTION + getInsetTop();
        } else {
          questionStyle.height = boxHeight * QUESTION_HEIGHT_OF_TOTAL_HEIGHT;
        }
      }
    }
  } else {
    console.log('parent max width and height not available', parentDomId);
  }

  var wrongStyle = {
    border: "3px ".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK), " solid"),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK)
  };
  var correctButNotChosenStyle = {
    border: "3px ".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correctButNotChosen', defaultTheme, 'correctButNotChosen', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK)), " solid"),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correctButNotChosen', defaultTheme, 'correctButNotChosen', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK))
  };
  var correctStyle = {
    border: "3px ".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK), " solid"),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK)
  };
  var textCorrectStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'textCorrect', defaultTheme, 'textCorrect', _ColorHelper.COLOR.BLACK)
  };
  var neutralStyle = {
    border: "3px ".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizSecondColor', defaultTheme, 'quizSecondColor', _ColorHelper.COLOR.BLACK), " solid")
  };
  var opponentAnswerStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'opponentAnswer', defaultTheme, 'opponentAnswer', _ColorHelper.COLOR.BLACK)
  };
  var selectedAnswerStyle = {// border: `6px ${getObjectValueWithFallbacks(theme, 'quizThirdColor', defaultTheme, 'quizThirdColor', COLOR.BLACK)} solid`
  };
  var answerTextStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'answerBoxText', defaultTheme, 'answerBoxText', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizText', defaultTheme, 'quizText', _ColorHelper.COLOR.BLACK))
  };
  var paddingTop = isInKesi ? 0 : 10;
  var outerBoxStyle = {
    width: boxStyle.width,
    margin: 'auto',
    paddingTop: paddingTop,
    marginTop: isInKesi ? '10px' : 0,
    textAlign: 'center',
    position: 'relative',
    height: '100%'
  };

  if (!isInKesi) {
    outerBoxStyle.height = isSinglePlayer ? '100%' : boxStyle.height - paddingTop;
  } else {
    outerBoxStyle.maxWidth = '100vw'; // outerBoxStyle.maxHeight = '100vh'
    // outerBoxStyle.minHeight = '500px'
    // outerBoxStyle.minWidth = '300px'

    outerBoxStyle.overflow = window.innerHeight < 500 ? 'scroll' : 'hidden';
  }

  return {
    questionStyle: questionStyle,
    answerStyle: answerStyle,
    answerStyleMCSC: answerStyleMCSC,
    wrongStyle: wrongStyle,
    correctStyle: correctStyle,
    correctButNotChosenStyle: correctButNotChosenStyle,
    textCorrectStyle: textCorrectStyle,
    neutralStyle: neutralStyle,
    opponentAnswerStyle: opponentAnswerStyle,
    answerTextStyle: answerTextStyle,
    boxStyle: boxStyle,
    singlePlayerBoxStyle: singlePlayerBoxStyle,
    outerBoxStyle: outerBoxStyle,
    selectedAnswerStyle: selectedAnswerStyle
  };
};

exports.getQuestionStyles = getQuestionStyles;

var getQuestionOverlayIconStyles = function getQuestionOverlayIconStyles(componentState, hasFeedback, isInKesi, questionHeight) {
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var correctWrongStyle = {};
  var smallLineStyle = {
    minHeight: 10
  };
  var thumbStyle = {};
  var textStyle = {};

  if (_config["default"].allowDifferentQuestionBoxSizes) {
    // 154 px is the height of the icon with text -> we will have at least 10px of padding
    correctWrongStyle.top = (componentState.questionBoxHeight - 154) / 2;
    var hw = questionHeight / 2.5;
    correctWrongStyle.height = hw;
    correctWrongStyle.width = hw;
    thumbStyle.fontSize = hw / 3;
    thumbStyle.marginTop = thumbStyle.fontSize / 10;
    textStyle.fontSize = thumbStyle.fontSize * 0.5;
    smallLineStyle.minHeight = textStyle.fontSize / 2;
  } else {
    console.log('questionHeight', questionHeight);

    if (questionHeight) {
      if (isInKesi) {
        var questionHeightToUse = questionHeight; // correctWrongStyle.top = '20px'

        var _hw = questionHeightToUse / 2.5;

        correctWrongStyle.height = _hw;
        correctWrongStyle.width = _hw;
        console.log('questionHeight123', questionHeight, _hw);
        thumbStyle.fontSize = _hw / 3;
        thumbStyle.marginTop = _hw / 10;
        textStyle.fontSize = _hw / 6;
        smallLineStyle.minHeight = '10px';
      } else {
        correctWrongStyle.top = hasFeedback ? questionHeight / 5 : questionHeight / 4;

        var _hw2 = questionHeight / 2.5;

        correctWrongStyle.height = _hw2;
        correctWrongStyle.width = _hw2;
        thumbStyle.fontSize = _hw2 / 3;
        thumbStyle.marginTop = thumbStyle.fontSize / 10;
        textStyle.fontSize = thumbStyle.fontSize * 0.5;
        smallLineStyle.minHeight = textStyle.fontSize / 2;
      }
    } else {
      // style for old standalone QD
      correctWrongStyle.top = hasFeedback ? '13%' : '20%';
      thumbStyle.fontSize = 50;
      thumbStyle.marginTop = 6;
      textStyle.top = 6;
    }
  }

  if (_config["default"].showMinimalizedFeedbackIcon) {
    thumbStyle.fontSize *= 1.8;
    thumbStyle.marginTop = '15%';
  }

  var wrongStyle = (0, _assign["default"])({
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK)
  }, correctWrongStyle);
  var correctStyle = (0, _assign["default"])({
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK)
  }, correctWrongStyle);
  var neutralStyle = (0, _assign["default"])({
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.WHITE),
    border: "2px solid ".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizThirdColor', defaultTheme, 'quizThirdColor', _ColorHelper.COLOR.WHITE))
  }, correctWrongStyle, {
    top: '50%',
    transform: 'translate(-50%, -50%)'
  });
  var evaluationStyle = (0, _assign["default"])(correctWrongStyle, {
    backgroundColor: 'white',
    top: questionHeight / 8,
    color: 'black'
  });
  return {
    wrong: wrongStyle,
    correct: correctStyle,
    neutral: neutralStyle,
    smallLine: smallLineStyle,
    thumb: thumbStyle,
    text: textStyle,
    evaluation: evaluationStyle
  };
};

exports.getQuestionOverlayIconStyles = getQuestionOverlayIconStyles;