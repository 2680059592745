"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  OWN_UNITS: {
    de: 'Eigeneinheiten'
  },
  STRUCTURE_UNITS: {
    de: 'Fremdeinheiten'
  },
  STRUCTURE_TARGET: {
    de: 'Strukturziel'
  },
  USE_PICTURE: {
    de: 'Verwenden'
  },
  USE_PICTURE_TITLE: {
    de: 'Bild verwenden'
  },
  RETAKE: {
    de: 'Nochmal'
  },
  CHOOSE_PICTURE_FROM_GALLERY: {
    de: 'Galerie'
  },
  CHOOSE_PICTURE_FROM_CAMERA: {
    de: 'Kamera'
  },
  MAYBE_LATER: {
    de: 'Später'
  },
  CHOOSE_PICTURE: {
    de: 'Wählen Sie ein Profilbild aus der Gallery oder machen Sie ein neues mit der Kamera'
  },
  CHOOSE_PICTURE_TITLE: {
    de: 'Profilbild festlegen'
  },
  DELETE_UNITS: {
    de: 'Löschen'
  },
  DELETE_UNITS_PER_MONTH: {
    de: 'Löschen Sie Ihre monatliche Einheiten'
  },
  YEAR: {
    de: 'Jahr'
  },
  BOOKED_UNITS: {
    de: 'Gebuchte Einheiten'
  },
  BOOK_UNITS: {
    de: 'Einheiten buchen'
  },
  UNITS: {
    de: 'Einheiten'
  },
  RESET_TARGET: {
    de: 'Ziel zurücksetzen'
  },
  ADD_UNITS: {
    de: 'Hinzufügen'
  },
  CHOOSE_USER_LEVEL: {
    de: 'Haben Sie eine ERGO Stufe? Wenn ja, wählen Sie diese hier aus.'
  },
  CHOOSE_USER_LEVEL_TITLE: {
    de: 'ERGO Stufe wählen'
  },
  ENTER_MONTHLY_TARGET: {
    de: 'Monatsziel festlegen'
  },
  ENTER_TARGET: {
    de: 'Neues Ziel'
  },
  ACTIVITIES_IN_MONTH: {
    de: 'Aktivitäten im $0'
  },
  ERGO_INFO_NO_BACKEND_TITLE: {
    de: 'Hinweis'
  },
  ERGO_INFO_NO_BACKEND: {
    de: 'Diese App ist in der Testphase noch nicht mit den ERGO Systemen verbunden, deshalb werden nur die manuellen Eingaben in der App berücksichtigt.'
  },
  HIGHSCORE_POINTS_TO_NEXT_RANK: {
    de: 'Dir fehlen <div style="color: #b9172f; display: inline-block;">$0</div> Einheiten bis zum nächsten Rang.'
  },
  HIGHSCORE_CURRENT_USER_RANK: {
    de: 'Du befindest dich zur Zeit auf Rang <div style="color: #b9172f; display: inline-block;">$0</div>'
  },
  STRUCTURE_HIGHSCORE: {
    de: 'Meine Strukturrangliste'
  },
  GLOBAL_HIGHSCORE: {
    de: 'Globale Rangliste (Top 20)'
  },
  USER_FIRST_NAME: {
    de: 'Vorname'
  },
  USER_LAST_NAME: {
    de: 'Nachname'
  },
  USER_EMAIL: {
    de: 'E-Mail Adresse'
  },
  USER_AGREED: {
    de: 'Datenschutzerklärung akzeptiert am'
  },
  NOT_SET: {
    de: 'Nicht angegeben'
  },
  MY_BOSS: {
    de: 'Meine Führungskraft'
  },
  MY_STRUCTURE: {
    de: 'Meine Struktur'
  },
  MY_PARTNERS: {
    de: 'Meine Vertriebspartner'
  },
  ACTIVATION_CODE_ALREADY_USED: {
    de: 'Dieser Aktivierungscode wurde bereits benutzt.',
    en: 'This activation code was already used.'
  },
  ACTIVATION_CODE_ERROR: {
    de: 'Es gab einen Fehler bei der Aktivierung Ihres Codes.',
    en: 'There was an error activating your code.'
  },
  ENTER_USER_DATA_TITLE: {
    de: 'Benutzerdaten eingeben'
  },
  ENTER_CREDENTIALS_TITLE: {
    de: 'Login'
  },
  ENTER_CREDENTIALS: {
    de: 'Bitte geben Sie Ihre E-Mailadresse und das zugehörige Passwort ein'
  },
  GOT_LOGIN: {
    de: 'Bereits registriert'
  },
  PERSONAL_NUMBER_ERROR: {
    de: 'Es gab einen Fehler bei der Eingabe Ihrer Personalnummer. Bitte versuchen Sie es erneut.'
  },
  ENTER_EMAIL_VERIFICATION_CODE_TEXT: {
    de: 'Bitte geben Sie den Registrierungscode aus der E-Mail an, die wir Ihnen soeben zugesandt haben.'
  },
  ENTER_EMAIL_VERIFICATION_CODE_TITLE: {
    de: 'Registrierungscode eingeben'
  },
  EMAIL_VERIFICATION_CODE: {
    de: 'Registrierungscode'
  },
  ACCEPT_USER_AGREEMENT_TEXT: {
    de: 'Bitte akzeptieren Sie die Datenschutzerklärung, um mit der Anmeldung fortzufahren.'
  },
  ACCEPT_USER_AGREEMENT_TITLE: {
    de: 'Datenschutzerklärung akzeptieren'
  },
  ENTER_PASSWORD_TEXT: {
    de: 'Bitte geben Sie Ihr Passwort ein.'
  },
  ENTER_PASSWORD_TITLE: {
    de: 'Passwort eingeben'
  },
  ENTER_PERSONAL_NUMBER_TEXT: {
    de: 'Bitte geben Sie Ihre Personalnummer ein. Im Anschluss senden wir Ihnen an die hinterlegte E-Mailadresse einen Registrierungscode.'
  },
  ENTER_PERSONAL_NUMBER_NO_MAIL_TEXT: {
    de: 'Bitte geben Sie Ihre Personalnummer ein.<br />(ohne Buchstaben)'
  },
  ENTER_PERSONAL_NUMBER_TITLE: {
    de: 'Personalnummer eingeben'
  },
  PASSWORD_RESET_ERROR: {
    de: 'Es gab einen Fehler beim Speichern Ihres neuen Passworts: $0'
  },
  ERGO_ENTER_EMAIL_TEXT: {
    de: 'Bisher ist zu Ihrem Account keine E-Mail gespeichert. Um Funktionen wie "Passwort zurücksetzen" nutzen zu können, geben Sie bitte Ihre E-Mailadresse ein.'
  },
  ERGO_ENTER_EMAIL_TITLE: {
    de: 'E-Mailadresse eingeben'
  },
  CHECK_EMAIL_TEXT: {
    de: 'Ist die eingegebene E-Mailadresse $0 korrekt? Sie kann danach nicht mehr geändert werden.'
  },
  CHECK_EMAIL_TITLE: {
    de: 'E-Mailadresse korrekt?'
  },
  ERGO_EMAIL: {
    de: 'E-Mailadresse'
  },
  EMAIL_SAVED_TITLE: {
    de: 'E-Mailadresse gespeichert'
  },
  EMAIL_SAVED_TEXT: {
    de: 'Vielen Dank. Die E-Mailadresse $0 wurde erfolgreich zu Ihrem Account hinzugefügt.'
  },
  ENTER_RESET_VERIFICATION_CODE_TEXT: {
    de: 'Bitte geben Sie den Verifizierungscode aus der E-Mail an, die wir Ihnen soeben zugesandt haben.'
  },
  ENTER_RESET_VERIFICATION_CODE_TITLE: {
    de: 'Verifizierungscode eingeben'
  },
  RESET_VERIFICATION_CODE: {
    de: 'Verifizierungscode'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;