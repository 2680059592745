"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.addAdditionalDataToError = addAdditionalDataToError;
exports.getErrorMessage = void 0;

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/json/stringify"));

var _HttpRequest = require("../utils/HttpRequest");

var _index = require("../KESI2/Services/LogService/index");

var _strings = require("../../config/strings");

// eslint-disable-line
var util = require('util');

var reportErrorToServer = function reportErrorToServer(messageDisplayedToUser, error) {
  (0, _index.pushErrorMessage)(messageDisplayedToUser, error);
};

var getErrorMessage = function getErrorMessage(errorOrMessage) {
  var addRetryText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var E_UNKNOWN_ERROR = 'unknown error';
  var ERROR_MESSAGES = [{
    match: 'username is already registered (duplicate)',
    text: 'E_USER_DUPLICATE'
  }, {
    match: 'username is empty',
    text: 'E_USERNAME_EMPTY'
  }, {
    match: 'deviceuuid is empty',
    text: 'E_DEVICEUUID_EMPTY'
  }, {
    match: 'deviceuuid has wrong format',
    text: 'E_DEVICEUUID_WRONG'
  }, {
    match: 'deviceuuid not owned',
    text: 'E_DEVICE_NOTOWNED'
  }, {
    match: 'username doesn\'t exists',
    text: 'E_USER_NOTFOUND'
  }, {
    match: 'device not found',
    text: 'E_DEVICE_NOTFOUND'
  }, {
    match: 'device locked',
    text: 'E_DEVICE_LOCKED'
  }, {
    match: 'must revalidate',
    text: 'E_MUST_REVALIDATE'
  }, {
    match: 'must activate',
    text: 'E_MUST_ACTIVATE'
  }, {
    match: 'pin locked',
    text: 'E_PIN_LOCKED'
  }, {
    match: 'firstname is empty',
    text: 'E_FIRSTNAME_EMPTY'
  }, {
    match: 'firstname has wrong format',
    text: 'E_FIRSTNAME_WRONG'
  }, {
    match: 'lastname is empty',
    text: 'E_LASTNAME_EMPTY'
  }, {
    match: 'lastname has wrong format',
    text: 'E_LASTNAME_WRONG'
  }, {
    match: 'userid is empty',
    text: 'E_USERID_EMPTY'
  }, {
    match: 'userid not found',
    text: 'E_USERID_NOTFOUND'
  }, {
    match: 'wrong usage recruiter exists',
    text: 'E_WRONG_RECREATE'
  }, {
    match: 'password too short',
    text: 'E_PASSWORD_SHORT'
  }, {
    match: 'cannot save user',
    text: 'E_USER_NOTSAVED'
  }, {
    match: 'cannot save device',
    text: 'E_DEVICE_NOTSAVED'
  }, {
    match: 'activation code revoked',
    text: 'E_CODE_REVOKED'
  }, {
    match: 'no personalization required',
    text: 'E_PERSONALIZATION_NOT_REQUIRED'
  }, {
    match: 'code is empty',
    text: 'E_CODE_EMPTY'
  }, {
    match: 'code has wrong format',
    text: 'E_CODE_WRONG'
  }, {
    match: 'code not found',
    text: 'E_CODE_NOT_FOUND'
  }, {
    match: 'code is used before valid',
    text: 'E_CODE_TOO_EARLY'
  }, {
    match: 'code is used after valid',
    text: 'E_CODE_TOO_LATE'
  }, {
    match: 'unsupported feature, update app',
    text: 'E_APP_NEEDSUPDATE'
  }, {
    match: 'Das Quiz wurde aktualisiert, zum Weiterspielen installieren sie bitte eine neuere Version der App aus dem',
    text: 'E_APP_OUTDATED'
  }, {
    match: 'code in use',
    text: 'E_CODE_IN_USE'
  }, {
    match: 'Die Gültigkeit Ihres Aktivierungscodes ist abgelaufen. Einen neuen erhalten Sie bei Ihrem Ansprechpartner.',
    text: 'E_CODE_EXPIRED'
  }, {
    match: 'panic: too many users registered for this code',
    text: 'E_CODE_TOO_MANY_USERS'
  }, {
    match: 'old password too short',
    text: 'E_OLDPASSWORD_SHORT'
  }, {
    match: 'new password too short',
    text: 'E_NEWPASSWORD_SHORT'
  }, {
    match: 'password doesnt match',
    text: 'E_PASSWORD_NOTMATCH'
  }, {
    match: 'no pin for requestCode',
    text: 'E_PIN_REQUESTCODE'
  }, {
    match: 'unknown mail',
    text: 'E_NO_EMAIL'
  }, {
    match: 'invalid email',
    text: 'E_INVALID_EMAIL'
  }, {
    match: 'invalid token sent',
    text: 'E_INVALID_TOKEN'
  }, {
    match: 'invalid signature',
    text: 'E_INVALID_SIGNATURE'
  }, {
    match: 'token already in use',
    text: 'E_TOKEN_USED'
  }, {
    match: 'maxSeconds expected',
    text: 'E_TIMERANGE_EXPECTED'
  }, {
    match: 'feature not found',
    text: 'E_FEATURE_NOTFOUND'
  }, {
    match: 'request not confirmed',
    text: 'E_NOT_CONFIRMED'
  }, {
    match: 'request expired',
    text: 'E_REQUEST_EXPIRED'
  }, {
    match: 'wrong code creation',
    text: 'E_WRONG_CODE_CREATION'
  }, {
    match: 'user not agreed',
    text: 'E_NOTAGREED'
  }, {
    match: 'playgroup not found',
    text: 'E_GROUP_NOTFOUND'
  }, {
    match: 'quizuser not saved',
    text: 'E_QUIZUSER_NOTSAVED'
  }, {
    match: 'nickname has wrong format',
    text: 'E_NICKNAME_WRONG_FORMAT'
  }, {
    match: 'nickname is used in this group',
    text: 'E_NICKNAME_USED'
  }, {
    match: 'nickname is empty',
    text: 'E_NICKNAME_EMPTY'
  }, {
    match: 'Network Error',
    text: 'E_NETWORK_ERROR'
  }, {
    match: /^.*timeout of .* exceeded.*$/g,
    text: 'E_NETWORK_TIMEOUT'
  }, {
    match: _HttpRequest.HTTP_ERROR_OFFLINE,
    text: 'E_OFFLINE_POST'
  }, {
    match: 'always running game present',
    text: 'E_RUNNING_GAME'
  }, {
    match: 'owner is not quizuser',
    text: 'E_NO_OWNER'
  }, // very improbable
  {
    match: 'no valid opponent',
    text: 'E_INVALID_OPPONENT'
  }, {
    match: 'no valid challenger',
    text: 'E_INVALID_CHALLENGER'
  }, {
    match: 'players equal',
    text: 'E_PLAYERS_EQUAL'
  }, {
    match: 'game not owned',
    text: 'E_GAME_NOT_OWNED'
  }, {
    match: 'game manipulated',
    text: 'E_MANIPULATED'
  }, {
    match: 'game not found',
    text: 'E_LASTGAME_NOTFOUND'
  }, {
    match: 'wrong category',
    text: 'E_WRONG_CATEGORY'
  }, {
    match: 'try to manipulate finished game',
    text: 'E_CHANGES_ON_FINISHED'
  }, {
    match: 'all seasons closed',
    text: 'E_ALL_SEASON_CLOSED'
  }, {
    match: 'all games with this opponent played',
    text: 'E_NO_MORE_GAMES_WITH_OPP'
  }, {
    match: 'no_validation_code_entered',
    text: 'E_NO_VALIDATION_CODE_ENTERED'
  }, {
    match: 'Invalid code',
    text: 'E_SAFETURE_INVALID_CODE'
  }, {
    match: 'Insufficient permission',
    text: 'E_INSUFFICIENT_PERMISSION_SAFETURE'
  }, {
    match: 'HTTP Error',
    text: 'E_SAFETURE_NO_CONNECTION'
  }, {
    match: 'No Internet connection',
    text: 'E_SAFETURE_NO_CONNECTION'
  }, {
    match: 'No internet connection to Safeture service',
    text: 'E_SAFETURE_NO_CONNECTION'
  }, {
    match: 'policy not found',
    text: 'E_SAFETURE_POLICY_NOT_FOUND'
  }, {
    match: 'wrong id format',
    text: 'E_SAFETURE_POLICY_WRONG_FORMAT'
  }, {
    match: 'too many registrations',
    text: 'E_SAFETURE_POLICY_TOO_MANY'
  }, {
    match: 'too much registrations',
    text: 'E_SAFETURE_POLICY_TOO_MANY'
  }, {
    match: 'policy expired',
    text: 'E_SAFETURE_POLICY_EXPIRED'
  }, {
    match: 'policy not yet valid',
    text: 'E_SAFETURE_POLICY_NOT_YET_VALID'
  }, {
    match: 'not registered',
    text: 'E_SAFETURE_POLICY_NOT_REGISTERED'
  }, {
    match: 'policy number expected',
    text: 'E_SAFETURE_POLICY_NO_NUMBER'
  }, {
    match: 'wrong policy number format',
    text: 'E_SAFETURE_POLICY_WRONG_FORMAT'
  }, {
    match: 'client id not in authorization',
    text: 'E_SAFETURE_POLICY_CLIENT_NOT_AUTHORIZED'
  }, {
    match: 'client phone number wrong format',
    text: 'E_SAFETURE_POLICY_PHONE_WRONG_FORMAT'
  }, {
    match: 'token not found',
    text: 'Der eingegebene Registrierungscode ist nicht gültig.'
  }, {
    match: 'messages not found',
    text: 'E_CONFIG_MESSAGES_NOT_FOUND'
  }, {
    match: 'event overbooked',
    text: 'E_EVENT_OVERBOOKED'
  }, {
    match: 'FRONT_CAMERA_UNAVAILABLE',
    text: 'E_FRONT_CAMERA_UNAVAILABLE'
  }, // ATTENTION:
  // these two have to be at the end -> default values!
  {
    match: _HttpRequest.HTTP_ERROR_PREFIX,
    text: 'E_HTTP_STATUS'
  }, {
    match: E_UNKNOWN_ERROR,
    text: 'E_UNKNOWN_ERROR'
  } // ATTENTION
  // no error message must come after the two messages above
  ];
  var errorMessage = '';
  var messageToCheck;
  var errorCode = null;

  if (errorOrMessage.hasOwnProperty('message')) {
    if (typeof errorOrMessage.message === 'string') {
      messageToCheck = errorOrMessage.message;
    } else {
      messageToCheck = errorOrMessage.message[0];
    }
  } else if (errorOrMessage.hasOwnProperty('name')) {
    // QRScanner returns an object with name. Under _message there is an English text
    messageToCheck = errorOrMessage.name;
  } else if (errorOrMessage.hasOwnProperty('code')) {
    messageToCheck = String(errorOrMessage.code);
  } else {
    messageToCheck = errorOrMessage.toString();

    if (!messageToCheck) {
      messageToCheck = E_UNKNOWN_ERROR;
      errorCode = messageToCheck.replace('ERROR_HTTP_STATUS_', '');
    }
  }

  console.log('errormessage', errorOrMessage, " (interpreted as ".concat(messageToCheck, ")"));
  var errorMessageTranslation = ERROR_MESSAGES.find(function (error) {
    return messageToCheck.match(error.match);
  });

  if (errorMessageTranslation) {
    errorMessage = (0, _strings.getString)(errorMessageTranslation.text);
  } else {
    errorMessage = (0, _strings.getString)('E_UNKNOWN_ERROR_WITH_REASON', [messageToCheck]);
  }

  if (addRetryText) {
    var errorCodeText = '';

    if (errorCode) {
      var errorNumberStyle = 'font-size: small; float: right; padding-top: 2px; position: absolute; right: 10px';
      errorCodeText = "<div style=\"".concat(errorNumberStyle, "\">").concat(messageToCheck.replace('ERROR_HTTP_STATUS_', ''), "</div>");
    }

    errorMessage += "<br/>Nochmal probieren?".concat(errorCodeText);
  }

  reportErrorToServer(errorMessage, errorOrMessage);
  return errorMessage;
};

exports.getErrorMessage = getErrorMessage;

function addAdditionalDataToError(error, additionalData) {
  if (!error.additionalData) {
    error.additionalData = [];
  }

  var jsonString;

  try {
    jsonString = (0, _stringify["default"])(additionalData);
  } catch (error) {
    jsonString = util.inspect(additionalData);
  }

  error.additionalData.push(jsonString);
}